<template>
  <div class="login_cover">
    <div class="login-box">
      <div class="left-img">
        <img src="@/assets/images/Full-Logo-Gold.png" class="site-logo" />
        <div class="email-call-info">
          <ul>
            <li>
              <a href="mailto:mfutch@srlife.net">
                <i class="fal fa-envelope"></i> mfutch@srlife.net
              </a>
            </li>
            <li>
              <a href="tel:8777778808">
                <i class="fal fa-phone-alt"></i> 877.777.8808
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="login-form">
        <v-form class="login-column">
          <div class="login-form-fild">
            <v-row>
              <v-col cols="12" md="12">
                <validation-provider
                  name="User Name"
                  rules="required|email"
                  mode="lazy"
                >
                  <v-text-field
                    label="Username"
                    hide-details=""
                    v-model="email"
                    class="form-control"
                    placeholder="Username"
                    required
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="12">
                <validation-provider>
                  <v-text-field
                    label="Password"
                    hide-details=""
                    class="form-control"
                    placeholder="Password"
                    required
                    outlined
                  >
                  </v-text-field>
                </validation-provider>
                <div class="forgot-password my-3 text-right font-weight-bold">
                  <a href="#">Forgot password?</a>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="text-center">
            <v-btn
              class="signin-btn"
              type="submit"
              color="primary"
              dark
              rounded
              :disabled="loading"
              :loading="loading"
            >
              Sign In
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
    <Footer />
    <div class="footer">
      <p>Senior Life Insurance Company Ⓒ Copyright 2022</p>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    ValidationProvider,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
